
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}


.modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
  width: 100%;
  max-width: 550px; 
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); 
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}


.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  font-size: 1.5rem;
  color: #333;
  margin-top: 10px;
  font-weight: bold;
}


.modal-icon {
  color: black;
}


.modal-body {
  margin-bottom: 30px;
}

.modal-reference,
.modal-date,
.modal-amount,
.modal-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

.modal-amount {
  font-weight: bold;
  color: #333;
}


.modal-footer {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}


.modal-footer button {
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 8px;

  cursor: pointer;
  width: 48%; 
  transition: all 0.3s ease;
}


.btn-download {
  background-color: white; 
  color: black; 
  border: 2px solid black; 
}

.btn-download:hover {
  background-color: black; 
  color: white; 
  border-color: black; 
}


.btn-done {
  background-color: #000; 
  color: white;
}

.btn-done:hover {
  background-color: #000;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


@media (max-width: 600px) {
  .modal-content {
    width: 90%;
  }

  .modal-footer {
    flex-direction: column;
    gap: 10px;
  }

  .modal-footer button {
    width: 100%;
  }
}

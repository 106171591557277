/* General body styles */

/* Dashboard Container */
.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensures the layout takes full viewport height */
}
/* Main Content Styles */
.dashboard-content {
  margin-left: 250px; /* Make space for the sidebar */
  padding: 20px;
  background-color: #f4f4f4; /* Light background color */
  min-height: 100vh; /* Ensure content fills the entire viewport height */
  transition: margin-left 0.3s ease; /* Smooth transition for sidebar toggle */
  overflow-y: auto; /* Enable scrolling if content overflows */
  box-sizing: border-box; /* Ensure padding does not affect width */
}

/* If sidebar is open (for small screens), adjust the content margin */
.dashboard-content.sidebar-open {
  margin-left: 0; /* Remove margin when sidebar is open (on small screens) */
}

/* Styling for content inside the dashboard */
.dashboard-content h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.dashboard-content p {
  font-size: 16px;
  line-height: 1.5;
  color: #555;
}

.dashboard-content .active {
  background-color: #e8b40a;
  color: #fff;
}

/* Ensure content does not overflow the page */
.content-wrapper {
  max-width: 100%;
  overflow: auto;
  box-sizing: border-box;
}

/* Responsive styles for small screens */
@media (max-width: 768px) {
  .dashboard-content {
    margin-left: 0; /* No margin for sidebar on small screens */
  }
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-right p {
  margin-right: 20px;
}

/* Main Content Container */
.main-content {
  display: flex;
  flex: 1;
}

/* Content Area */
.main-content {
  display: flex;
  flex: 1;
  margin-left: 250px; /* Adjust the content area when the sidebar is open */
  transition: margin-left 0.3s ease;
}
.content {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  overflow-y: auto;
  box-sizing: border-box;
}

.content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

.modal p {
  font-size: 16px;
  color: #555;
}

.modal-icon {
  width: 42px;
  height: 69px;
  margin: 10px 0;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10px;
}

.back-button,
.confirm-button {
  border: 2px solid transparent;
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s, border 0.3s;
}

.back-button {
  background: #fff;
  color: #333;
  border: 2px solid #000;
}

.confirm-button {
  background: #000;
  color: white;
}

.back-button:hover {
  background: #f4f4f4;
  border-color: #333;
}

.confirm-button:hover {
  background: #444;
}

.cart-container {
  background-color: #fff8e1;
  width: 545px;
  height: 157px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  border: 1px solid #000;
}

.cart-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.cart-table th {
  background-color: #fff8e1;

  font-size: 10px;

  border: none;
  text-align: left;
}

.cart-table td {
  font-size: 10px;

  border: none;
  text-align: left;
}

.cart-table input {
  width: 60px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  background-color: #fff;
}

.remove-btn {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 4px 8px;
  font-size: 12px;
}

.remove-btn:hover {
  background-color: #d32f2f;
}

.total-section {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}

.deduct {
  margin-right: 40px;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #fff;
}
/* General Styles */

.login-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

/* Left Side Styling */
.login-left {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 620px;
  padding: 20px;
}

.image-container {
  position: relative;
  width: 100%;
}

.login-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.logo-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px;
}

.logo-image {
  width: 100px; /* Adjust size as needed */
  height: auto;
}

/* Right Side Styling */
.login-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.login-box {
  position: relative;
  width: 400px;
  padding: 20px;

  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.image-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 150px;
  height: auto;
  z-index: 0;
}

.image-overlay img {
  width: 100%;
  height: 100%;
  object-fit: contain;

  pointer-events: none;
}
.login-box h2 {
  color: #555;
  margin-top: 20px;
  margin-bottom: 8px;
  text-align: left;
  font-size: 20px;
  text-transform: uppercase;
  z-index: 10;
}

.login-box h1 {
  color: #2a7c44;
  font-size: 38px;
  margin-bottom: 16px;
  text-align: left;
  text-transform: capitalize;
  z-index: 10;
}

.login-box p {
  color: #888;
  font-size: 14px;
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 8px;
  text-align: left;
}

input {
  width: 100%;
  padding: 10px 0; /* Remove side padding */
  margin-bottom: 20px;
  border: none; /* Remove all borders */
  border-bottom: 1px solid #ccc; /* Add only the bottom border */
  font-size: 16px;
  outline: none; /* Remove focus outline */
  background-color: transparent; /* Ensure no background color */
  color: #555;
}
input:focus {
  border-bottom: 2px solid #f0a500; /* Change border color on focus */
}

input::placeholder {
  color: #aaa;
  font-size: 14px;
}
.btn {
  width: 100%;
}
.login-button {
  background-color: #f0a500;
  color: #fff;
  border: none;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  gap: 8px;
  margin-bottom: 50px;
}

.login-button:hover {
  background-color: #e59400;
}
.password-input-container {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #555;
  font-size: 18px;
  cursor: pointer;
  outline: none;
}

/* Optional: Change color on hover */
.password-toggle:hover {
  color: #f0a500;
}

.error-message {
  color: red;
  font-size: 14px;
}
button:disabled {
  background-color: #ccc;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    margin-bottom: 20px;
  }

  .main-content {
    padding: 10px;
  }
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-left,
  .login-right {
    /* flex: none; */
    width: 100%;
  }

  .login-box {
    margin: 20px;
  }
  .logo-overlay {
    top: 60px;
  }
  .image-overlay {
    width: 120px;
  }
}

/* Common Styles for Tables */
table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

table th {
  background-color: #f4f4f4;
}

.transactions-container,
.reports-container {
  margin-top: 20px;
  padding: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 18px;
  color: #888;
}

table {
  margin-top: 20px;
}

table th,
table td {
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .transactions-container,
  .reports-container {
    padding: 10px;
  }

  table {
    width: 100%;
  }
}

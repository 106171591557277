.product-card {
  width: 300px; /* Card width */

  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  transition: transform 0.3s ease;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-details {
  width: 100%;
  /* margin-bottom: 10px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-name {
  font-size: 8px;
  font-weight: bold;
  color: #333;
  text-align: left;
}

.product-price {
  font-size: 14px;
  color: gray; /* Price color */
  /* margin-top: 5px; */
}

.add-to-cart {
  width: 100%;
  display: flex;
  justify-content: center;
}

.add-to-cart-btn {
  background-color: #fff;
  color: #000;
  border: none;
  /* border-radius: 100%; */
  /* padding: 3px; */
  cursor: pointer;
  /* transition: background-color 0.3s ease; */
}

.add-to-cart-btn:hover {
  background-color: white;
}

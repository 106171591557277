.dashboard-detail {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-direction: column;
}

.product-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

/* .product-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f8f8f8;
  border-bottom: 2px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
} */

/* Title Styling */
.product-header h1 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin: 0 0 20px 0;
}

.cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  gap: 10px;
}

/* Back Button Styling */
.btn-back {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #e8b40a;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-back:hover {
  background-color: #e8b40a;
}

.btn-back svg {
  margin-right: 8px;
}

/* Product Detail Text */
.detail-prod {
  font-size: 1rem;
  color: #555;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.3s ease;
}

.detail-prod:hover {
  color: #e8b40a;
}

.product-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.price {
  margin-right: 133px;
}
.th {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mr {
  margin-left: 55px;
  text-align: right;
}
.product-table-wrapper {
  background-color: #fff;
  width: 456px;
  height: 213px;
  overflow-y: auto;
  border: 1px solid #000;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.product-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  overflow-y: auto;
  display: block;
  margin-top: 20px;
  border-radius: 12px;
}

.product-table th,
.product-table td {
  border: none;
  padding: 12px;
  text-align: center;
}

.product-table th {
  background-color: #fff;
}

.product-table td {
  background-color: #fff;
}

.product-table tr:hover {
  background-color: #fff;
}

.cart {
  width: 50%;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cart ul {
  list-style-type: none;
  padding: 0;
}

.cart button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.cart button:disabled {
  background-color: #ccc;
}

.cart .error {
  color: red;
}

/* Sidebar Styles (for large screens) */
.sidebar {
  width: 250px; /* Ensure sidebar takes a fixed width */
  background-color: #fff;
  color: #575757;
  padding-top: 20px;
  position: fixed;
  top: 0;
  left: 0; /* Ensure it stays visible */
  height: 100%;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Adjust main content for large screens */
.main-content {
  display: flex; /* Ensures content area is side by side with sidebar */
  margin-left: 250px; /* Make room for the sidebar */
  flex: 1;
  padding: 20px;
}

.sidebar.open {
  left: 0; /* Show sidebar when open */
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
  margin-top: 70px;
  flex-grow: 1; /* Take remaining space */
}

.sidebar nav ul li {
  margin: 20px 0;
}

.sidebar nav ul li a {
  color: #575757;
  text-decoration: none;
  padding: 12px 20px;
  display: block;
  font-size: 1.1rem;
  transition: background-color 0.3s;
}

.sidebar nav ul li a:hover,
.sidebar nav ul li a.active {
  color: #000;
  border-left: 5px solid #e8b40a;
  background: white;
}

/* Username and Logout Button at the Bottom */
.sidebar-footer {
  padding: 20px;
  background-color: #444;
  margin-top: auto; /* Push footer to the bottom */
  display: none;
}

.sidebar-footer p {
  color: white;
  margin-bottom: 10px;
}

.sidebar-footer .logout-button {
  width: 100%;
  background-color: #ff5c5c;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  padding: 10px;
  text-align: center;
}

.sidebar-footer .logout-button:hover {
  background-color: #ff3a3a;
}

/* Hamburger Menu Button (for mobile view) */
.hamburger-menu {
  display: none; /* Hide by default on large screens */
  cursor: pointer;
}

/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2a2a2a; /* Dark background for header */
  color: white;
  background-image: url("/public//assets/header.png");
  background-size: cover;
  background-position: center;
  top: 0;
  z-index: 1000;
  
}

.header h1 {
  font-size: 24px;
  font-weight: bold;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right p {
  margin-right: 15px;
  font-size: 1rem;
  color: #fff;
  text-transform: capitalize;
}
.username {
  text-transform: uppercase;
}

.logout-button {
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(244, 255, 252, 0.4);
  border: 1px solid #f4fffc;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  /* Add background blur effect */
  backdrop-filter: blur(5px); /* Adjust the blur intensity as needed */
}

/* Optionally add a hover effect for background color */
.logout-button:hover {
  background-color: rgba(0, 0, 0, 0.5); /* Slightly more opaque when hovered */
}

/* Main Content Styles */
.content {
  margin-left: 250px; /* Space for sidebar */
  padding: 20px;
  background-color: #f4f4f4;
  min-height: 100vh;
  transition: margin-left 0.3s ease;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  /* Hide sidebar by default on small screens */
  .sidebar {
    transform: translateX(-100%); /* Sidebar is hidden initially on mobile */
  }

  .sidebar.open {
    transform: translateX(0); /* Show sidebar when open on mobile */
  }

  .hamburger-menu {
    cursor: pointer;
    display: block; /* Show hamburger icon on mobile */
  }

  

  .header-right {
    display: none; /* Hide the username and logout button on mobile */
  }

  .content {
    margin-left: 0; /* Remove sidebar space on small screens */
  }
  .sidebar nav ul {
    margin-top: 100px;
  }
  /* Adjust sidebar footer for mobile */
  .sidebar-footer {
    display: block;
    padding: 10px;
    text-align: center;
  }
}
